import mixins from 'vue-typed-mixins';
import { vfjsFieldMixin } from 'vue-form-json-schema/dist/vue-form-json-schema.esm';

export const BaseWrapperMixin = mixins(vfjsFieldMixin).extend({
  computed: {
    errors(): string[] {
      if (!this.vfjsOptions.showValidationErrors) {
        return [];
      }
      return this.vfjsFieldErrors
        .map((error) => error.message)
        .filter((message): message is string => !!message);
    },
  },
});
